/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f6e22171-217c-4100-8308-2c12854942fd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yC9XAZR8Z",
    "aws_user_pools_web_client_id": "173allamn7i5t1asa3n5crc6dh",
    "oauth": {}
};


export default awsmobile;
